/**
 * Input Schema of DeveloperAid
 * @author Simpli CLI generator
 */
import {DefaultSchema} from '@/schema/DefaultSchema'
import {FieldSet, FieldComponent} from '@simpli/meta-schema'
import * as Component from '@simpli/vue-input'
import {DeveloperAid} from '@/model/resource/DeveloperAid'
import FileManagerWithList from '@/components/FileManagerWithList.vue'
import {$} from '@/facade'
import RichText from '@/components/RichText.vue'

/* TODO: review generated schema */
export class InputDeveloperAidSchema extends DefaultSchema {
  readonly name = 'InputDeveloperAid'

  readonly fieldSet: FieldSet<DeveloperAid> = {
    imageUrl: (schema): FieldComponent => ({
      is: FileManagerWithList,
      bind: {
        folder: 'developer_aid',
        compressDimension: $.auth.global.dappImageDimension,
        crop: false,
        class: 'scoped__image-url',
      },
    }),
    callToActionText: (schema): FieldComponent => ({
      is: Component.InputText,
      bind: {
        type: 'text',
        maxlength: 70,
        label: this.translateFrom(schema.fieldName),
        required: true,
        validation: 'required',
      },
    }),
    actionUrl: (schema): FieldComponent => ({
      is: Component.InputText,
      bind: {
        type: 'text',
        maxlength: 255,
        label: this.translateFrom(schema.fieldName),
        required: true,
        validation: 'required',
      },
    }),
    description: (schema): FieldComponent => ({
      is: RichText,
      bind: {
        maxlength: 10000,
        label: this.translateFrom(schema.fieldName),
        required: true,
        validation: 'required',
        class: 'scoped__input-description',
      },
    }),
  }
}
