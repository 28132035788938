


































import {Component, Inject, Prop, Vue} from 'vue-property-decorator'
// @ts-ignore
import {quillEditor} from 'vue-quill-editor'

@Component({
  components: {quillEditor},
})
export default class RichText extends Vue {
  @Prop({type: String, required: true}) value!: string
  @Prop({type: String, default: ''}) label!: string
  @Prop({type: String, default: ''}) placeholder!: string
  @Prop({type: Boolean, default: false}) required!: boolean
  @Prop({type: Number, default: 3000}) maxlength!: number
  @Prop({default: null})
  validation!: any

  @Inject({from: 'validator', default: null})
  validator: any

  editor: any | null = null

  config = {
    placeholder: this.placeholder,
    modules: {
      toolbar: {
        container: [
          ['bold', 'italic', 'underline', 'strike'],
          [{list: 'ordered'}, {list: 'bullet'}],
          [{indent: '-1'}, {indent: '+1'}],
          [{size: ['small', false, 'large', 'huge']}],
          [{color: []}, {background: []}],
          [{align: []}],
          ['clean'],
        ],
      },
    },
  }

  created() {
    if (this.validator) {
      // @ts-ignore
      this.$validator = this.validator
    }
  }

  get isInvalid() {
    // @ts-ignore
    return this.errors.first(this.label)
  }

  characterLimitValidation() {
    this.editor.on('text-change', () => {
      if (this.editor.getLength() > this.maxlength) {
        this.editor.deleteText(this.maxlength, this.editor.getLength())
      }
    })
  }

  onEditorReady(quill: any) {
    this.editor = quill
  }
}
