



































import {Component, Prop, Watch, Provide, Vue} from 'vue-property-decorator'
import {DeveloperAid} from '@/model/resource/DeveloperAid'
import {InputDeveloperAidSchema} from '@/schema/resource/DeveloperAid/InputDeveloperAidSchema'
import RichText from '@/components/RichText.vue'
@Component({
  components: {RichText},
})
export default class PersistDeveloperAidView extends Vue {
  @Provide('validator') validator = this.$validator

  schema = new InputDeveloperAidSchema()
  developerAid = new DeveloperAid()

  async created() {
    await this.populate()
  }

  async populate() {
    await this.developerAid.getDeveloperAid()
    this.$await.done('getDeveloperAid')
  }

  async persist() {
    const isValid = await this.validator.validateAll()

    if (!isValid) {
      this.$toast.abort('system.error.validation')
    }

    await this.developerAid.persistDeveloperAid()
    this.$toast.success('system.success.persist')
  }
}
